<template>
  <CreatePassword>
    <template
      #first-title
    >
      RESET PASSWORD
    </template>
    <template
      #second-title
    >
      Reset your password
    </template>
    <template
      #third-title
    >
      Resest password for <b>{{ email }}</b>
    </template>
  </CreatePassword>
</template>

<script>
import CreatePassword from '@/modules/auth/views/CreatePassword.vue'
import axios from '@/helpers/axios'

export default {
  name: 'ForgotPassword',
  components: {
    CreatePassword,
  },
  data () {
    return {
      email: '',
    }
  },
  created () {
    axios.get('/public/v2/password/user/' + this.$route.query.token)
      .then(({ data }) => {
        this.email = data
      })
  },
}
</script>

<style lang="scss" scoped>

</style>

<template>
  <b-container
    class="vh-80 d-flex align-items-center justify-content-center text-center"
    fluid
  >
    <b-row>
      <b-col class="large-col pl-5 pr-5">
        <router-link to="/">
          <LogoWithTextDark class="mb-4 mr-4" />
        </router-link>
        <b-alert
          :show="!!error"
          variant="danger"
        >
          {{ error }}
        </b-alert>
        <b-col class="first-text text-left pl-0 mt-3">
          <slot name="first-title">
            ACCOUNT SETUP
          </slot>
        </b-col>
        <b-col class="second-text text-left pl-0 mt-1">
          <slot name="second-title">
            Create a password
          </slot>
        </b-col>
        <b-col class="third-text text-left pl-0 mt-3">
          <slot name="third-title">
            Enter a new password for <b>{{ email }}</b>
          </slot>
        </b-col>
        <b-form @submit.prevent="submit">
          <b-form-input
            id="relancer-new-password"
            v-model="form.newPassword"
            type="password"
            required
            placeholder="Enter new password"
            class="mt-3 mb-3"
          />

          <b-form-input
            id="relancer-confirm-password"
            v-model="form.confirmPassword"
            required
            placeholder="Confirm new password"
            type="password"
            class="mb-3"
          />
          <b-form-checkbox
            v-if="$route.path === '/create-password'"
            id="form.terms"
            v-model="form.terms"
            required
            class="terms-box mt-4"
            name="form.terms"
            :value="true"
            :unchecked-value="false"
          >
            I have read and agree with <a
              target="_blank"
              href="https://relancer.com/terms"
            >Terms of Service</a>
          </b-form-checkbox>

          <b-button
            v-if="$route.path === '/create-password'"
            class="w-100 mt-5 mb-5"
            type="submit"
            variant="primary"
          >
            Continue
          </b-button>
          <b-button
            v-if="$route.path === '/forgot-password'"
            class="w-100 mt-5 mb-5"
            type="submit"
            variant="primary"
          >
            Confirm
          </b-button>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import LogoWithTextDark from '@/components/svgs/LogoWithTextDark.vue'
import axios, { readError } from '@/helpers/axios'

export default {
  name: 'CreatePassword',

  components: {
    LogoWithTextDark,
  },

  data () {
    return {
      form: {
        newPassword: '',
        confirmPassword: '',
        terms: false,
      },
      error: '',
      email: '',
    }
  },
  created () {
    axios.get('/public/v2/password/user/' + this.$route.query.token)
      .then(({ data }) => {
        this.email = data
      })
      .catch(err => {
        this.error = readError(err)
      })
  },
  methods: {
    submit () {
      // Do not allow to pass without accepting terms
      if (this.$route.path === '/create-password' && !this.form.terms) {
        return
      }
      // Ensure passwords match
      if (this.form.newPassword !== this.form.confirmPassword) {
        this.error = 'Passwords are not matching'
        return
      }
      // Send PW reset off to backend
      axios.post('/public/v2/password/reset', {
        password: this.form.newPassword,
        password_confirmation: this.form.confirmPassword,
        token: this.$route.query.token,
      })
        .then(() => {
          this.$bvModal.msgBoxOk('Password is set. Log in with your new password', {
            title: 'Password set!',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true,
          }).then(() => {
            this.$router.push('/login')
          })
        })
        .catch(error => {
          this.error = readError(error)
        })
    },
  },

}
</script>

<style lang="scss" scoped>
.vh-80 {
  height: 80vh;
  background:#E5E5E5;
}

.large-col {
  width: 400px;
  height: 550px;
  background: #FFF;
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.07);
}

.first-text {
  color: #EE8B7B;
  font-size: 14px;
  font-weight: bold;
}

.second-text {
  color: #19242C;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}

.third-text {
  color: #465C6C;
  font-size: 12px;
  font-weight: 300;
  line-height: 26px;
}

.terms-box {
  color: #455360;
  font-size: 12px;
  line-height: 26px;
}
</style>
